body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

table,
th,
td {
  border: 1px solid #e3e3e3;
  border-collapse: collapse;
}

div#apexchartsbasicxbar {
  width: 100%;
}
.ant-picker-dropdown {
  z-index: 100000 !important;
}

/** Styling For Responsiveness */

@media screen and (max-width: 480px) {
  div.upload-image-main {
    display: contents;
  }
  div.left-container-upload-image {
    width: 100%;
    text-align: center;
  }
  div.line-upload-image {
    display: none;
  }
  div.right-container-upload-image {
    width: 100%;
    align-items: center;
  }
  div.right-cont-sub {
    min-width: 320px;
  }
}

/** Styling For Responsiveness */

/** Styling For Login Form */

div.ok1 {
  height: 685px;
}

h3.ok3 {
  align-self: start;
  margin-left: 13%;
  margin-top: 12%;
  margin-bottom: 10%;
  border-bottom: 2px solid darkolivegreen;
  width: 73%;
  color: white;
}

input.email-input {
  background: darkslategray;
  border: none;
  border-bottom: 1px solid white;
  border-radius: 0px;
  color: white;
}

.password-input > .ant-input.ant-input-lg {
  margin: 16% 0%;
  background: darkslategray;
  border: none;
  border-bottom: 1px solid white;
  border-radius: 0px;
  color: white;
}

.sign-up-m {
  margin-left: 0px;
}

/** Styling For Login Form */

/** Styling For SignUp Form */

h3.ok4 {
  align-self: start;
  margin-left: 13%;
  margin-top: 7%;
  margin-bottom: 5%;
  border-bottom: 2px solid darkolivegreen;
  width: 73%;
  color: white;
}

.password-input-signup > .ant-input.ant-input-lg {
  margin: 0% 0%;
  background: darkslategray;
  border: none;
  border-bottom: 1px solid white;
  border-radius: 0px;
  color: white;
}

div.ant-select-selection.ant-select-selection--single {
  background: darkslategray;
  border: none;
  border-bottom: 1px solid white;
  border-radius: 0px;
}

.ant-select-show-search.ant-select-auto-complete.ant-select.ant-select-combobox.ant-select-enabled {
  margin: 4% 0%;
}

/** Styling For SignUp Form */

/** Styling For Upload Image */

div.fileContainer {
  border: 2px solid black;
}

div.right-container-upload-image {
  height: 450px;
  overflow: auto;
}

/** Styling For Upload Image */

/** Styling For Card */

.flip-card {
  background-color: transparent;
  width: 380px;
  height: 150px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}

.ant-btn.flip-card-button {
  margin: 16% auto;
}

/** Styling For Card */

/** Styling For Card Live Image */

.flip-card-live {
  background-color: transparent;
  width: 85%;
  height: 150px;
  perspective: 1000px;
  margin: auto;
}

.flip-card-inner-live {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card-live:hover .flip-card-inner-live {
  transform: rotateY(180deg);
}

.flip-card-front-live,
.flip-card-back-live {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front-live {
  background-color: #bbb;
  color: black;
}

.flip-card-back-live {
  color: white;
  transform: rotateY(180deg);
}

.live-image-button {
  margin: 25px auto 0px;
  font-size: large;
  font-weight: bold;
  width: 40%;
  background: darkseagreen;
}

/** Styling For Card Live Image */

/** Styling For Responsive Modules */
@media screen and (max-width: 480px) {
  main.ant-layout-content {
    margin: 0px !important;
  }
  div.site-layout-background {
    padding: 15px;
  }
  input.ant-input.ant-input-lg {
    width: 100%;
  }
  div.rdrCalendarWrapper.rdrDateRangeWrapper {
    font-size: 9px;
  }
  textarea.ant-input {
    width: 100% !important;
  }
}
/** Styling For Responsive Modules */

/* Styling For Image Uploader */

div.fileUploader.add-images-uploader {
  width: 50%;
}

.fileUploader.add-images-uploader > .fileContainer {
  justify-content: flex-end;
}

/* Styling For Image Uploader */

/* Styling For Add Images in Add Image/Link */
@media screen and (max-width: 480px) {
  div.fileUploader.add-images-uploader {
    width: 100%;
  }
}
/* Styling For Add Images in Add Image/Link */

/*Styling For Description Editor in Add Description */

@media screen and (max-width: 480px) {
  div.editor-change {
    width: 100%;
  }
}

/*Styling For Description Editor in Add Description */

/* Styling For Responsiveness */

@media screen and (max-width: 480px) {
  div.ok1 {
    flex-flow: column;
    height: 929px;
    width: 525px;
  }
  div.ok2 {
    width: 96%;
    text-align: center;
  }
}

/* Styling For Responsiveness */

th.MuiTableCell-root.MuiTableCell-head.MUIDataTableHeadCell-root-138.MUIDataTableHeadCell-fixedHeader-139 {
  padding: 0px 60px;
}

/* Styling For Responsiveness */

/** Styling For Multiple Selects in Add Description */

div.ant-select-selection.ant-select-selection--single {
  background: none;
}

/** Styling For Multiple Selects in Add Description */

/** Styling for Category box */

span.ant-typography.just {
  display: flex;
}

div.hi1.ant-select-show-search.ant-select-auto-complete.ant-select.ant-select-combobox.ant-select-enabled {
  margin-left: 37%;
  width: 480px !important;
}

span.ant-typography.just > div {
  display: flex;
  margin-right: 40px !important;
  width: 100%;
  justify-content: flex-end;
  /* overflow: auto; */
}

div.ant-select-show-search.ant-select-auto-complete.ant-select.ant-select-combobox.ant-select-enabled {
  margin-left: 20%;
}

.auto-complete {
  margin-left: unset !important;
  margin: unset !important;
  width: 100% !important;
}

/** Styling for Category box */

#atcelement_popup .e-dropdownbase .e-highlight {
  color: #ff4081;
}
/* 
.MUIDataTableBodyCell-root-71 {
  width: 25px;
} */
label {
  font-weight: bold;
  font-size: 18px;
}

.tablefit table{
  width: auto !important;
}

.csm_clicks{
  cursor: pointer;
}


.width_75{
  width:75% !important;
}
.height_75{
  height: 75% !important;
}
.width_60{
  width:60% !important;
}
.height_60{
  height: 60% !important;
}